/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import style from "./addEditSubAdmin.module.scss";
import Button from "../../../../../shared/components/button/button";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import { SUB_ADMIN } from "../../subAdminUserRolesContants";
import Input from "../../../../../shared/components/inputFields/input";
import ImageUpload from "../../../../../shared/components/imageUpload/imageUpload";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import Back from "../../../../../shared/components/backButton/backButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AWS from "aws-sdk";
import { useDispatch } from "react-redux";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";
import {
  addSubAdmin,
  viewSubAdmin,
} from "../../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import { editSubAdmin } from "../../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import { getRoles } from "../../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import { maskPhoneNumber } from "../../../../../hooks/useMaskPhoneNumber";
import { generateUUID } from "../../../../../hooks/uniqueIdGenerator";

const AddEditSubAdmin = (props) => {
  const { isModalClosed, isEdit, permissions } = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [toastList, setToastList] = useState([]);
  const [formData, setFormData] = useState({});
  const [userImage, setUserImage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const location = useLocation();
  const navigate = useNavigate();
  const [roles, setRoles] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [removeImage, setRemoveImage] = useState(false);
  const { id } = useParams();
  const inputFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      label: "Name",
      required: true,
      width: "50%",
    },
    {
      type: "text",
      name: "email",
      placeholder: "Email ID",
      label: "Email ID",
      required: true,
      width: "50%",
    },
    {
      type: "password",
      name: "password",
      placeholder: "Password",
      label: "Password",
      required: location.pathname.includes("/subAdmin/editSubAdmin")
        ? false
        : true,
      width: "50%",
    },
    {
      type: "password",
      name: "passwordConfirm",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      required: location.pathname.includes("/subAdmin/editSubAdmin")
        ? false
        : true,
      width: "50%",
    },
    {
      type: "text",
      name: "contactNumber",
      placeholder: "(000) 000-0000",
      label: "Contact Number",
      pattern: "[0-9.]+",
      maxLength: 10,
      width: "50%",
    },
    {
      type: "dropdown",
      name: "userRole",
      options: roles,
      required: true,
      placeholder: "Single Selection",
      label: "User Role",
      width: "50%",
    },
  ];

  useEffect(() => {
    const hasEmptyRequiredFields = inputFields
      .filter((field) => field.required)
      .some((field) => !formData[field.name]);
    setIsSubmitDisabled(hasEmptyRequiredFields);
  }, [inputFields, formData]);

  useEffect(() => {
    setSelectedOptions([]);
    const params = {
      isAdmin: true,
    };
    getAllRoles(axiosInstance, params);
    if (id) {
      viewSubAdminUser(axiosInstance, params, id);
    }
  }, []);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const uuid = generateUUID();
    const fileKey = `sub-admin-${uuid}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        if (upload.Location && !upload.Location?.includes("undefined")) {
          formData["profilePic"] = upload.Location;
        } else {
          formData.profilePic = null;
        }
        if (!formData?.userRole?.value) {
          return setToastList([
            {
              id: 0,
              // title: "Error",
              title: "Please select a user role.",
              icon: "error",
            },
          ]);
        }
        formData.roleId = formData?.userRole?.value;
        const params = {
          isAdmin: true,
        };
        delete formData.__v;
        delete formData._id;
        delete formData.userRole;
        delete formData.created_at;
        delete formData.modified_at;

        !location.pathname.includes("/subAdmin/editSubAdmin")
          ? addEditSubAdmin(axiosInstance, params, formData)
          : addEditSubAdmin(axiosInstance, params, formData, id);
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the resume.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const getAllRoles = (axiosInstance, params) => {
    dispatch(getRoles(axiosInstance, params))
      .then((response) => {
        const updatedRoles = response.map((role) => {
          return {
            value: role._id,
            label: role.roleName,
          };
        });
        setRoles(updatedRoles);
      })
      .catch((error) => {
        console.log("err", error.response.data.message);
        setToastList([
          {
            id: 0,
            // title: "Error fetching roles",
            title: error.response.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const viewSubAdminUser = (axiosInstance, params, userId) => {
    dispatch(viewSubAdmin(axiosInstance, params, userId))
      .then((response) => {
        const res = response;
        setFormData({
          ...res,
          userRole: {
            label: response?.roleId?.roleName,
            value: response?.roleId?._id,
          },
        });
      })
      .catch((error) => {
        console.log("er", error);
        setToastList([
          {
            id: 0,
            // title: "Error viewing user",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const addEditSubAdmin = (axiosInstance, params, userDetails, userId) => {
    if (!userImage.name) {
      formData.profilePic = null;
    }

    !location.pathname.includes("/subAdmin/editSubAdmin")
      ? dispatch(addSubAdmin(axiosInstance, params, userDetails))
          .then((response) => {
            setToastList([
              {
                id: 0,
                // title: "Sub Admin Added",
                title: "SubAdmin was added successfully",
                icon: "success",
              },
            ]);
            setTimeout(() => {
              setRemoveImage(true);
              setFormData({});
              setSelectedOptions([]);
              navigate("/subAdmin");
            }, 1500);
          })
          .catch((error) => {
            console.log("err", error);
            setToastList([
              {
                id: 0,
                // title: "Error adding user",
                title: error?.response?.data?.message,
                icon: "error",
              },
            ]);
          })
      : dispatch(editSubAdmin(axiosInstance, params, userDetails, userId))
          .then((response) => {
            setToastList([
              {
                id: 0,
                // title: "Sub Admin Updated successfully",
                title: "Sub Admin details updated successfully",
                icon: "success",
              },
            ]);
            setTimeout(() => {
              setRemoveImage(true);
              setFormData({});
              setSelectedOptions([]);
              navigate("/subAdmin");
            }, 1500);
          })
          .catch((error) => {
            console.log("err", error?.response?.data?.message);
            setToastList([
              {
                id: 0,
                // title: "Error updating user",
                title: error?.response?.data?.message,
                icon: "error",
              },
            ]);
          });
  };

  const handleFile = (url) => {
    if (url) {
      setUserImage(url);
    }
  };

  const handleInputChange = (fieldName, fieldValue) => {
    if (fieldName === "contactNumber") {
      const input = fieldValue.toString().replace(/\D/g, "");
      const maskedInput = maskPhoneNumber(input);
      setFormData({ ...formData, [fieldName]: maskedInput });
    } else {
      setFormData({ ...formData, [fieldName]: fieldValue });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (userImage?.name) {
      uploadToS3(userImage, userImage.name);
    } else {
      if (!formData?.userRole?.value) {
        return setToastList([
          {
            id: 0,
            // title: "Error",
            title: "Please select a user role.",
            icon: "error",
          },
        ]);
      }
      formData.roleId = formData?.userRole?.value;

      const params = {
        isAdmin: true,
      };
      addEditSubAdmin(axiosInstance, params, formData, id);
    }
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[a-z0-9.]+@[a-z]+\.[a-z]{1,3}$/;
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    const nameRegex = /^[a-zA-Z ]+$/;
    if (name === "name") {
      if (value === "") {
        setFormErrors({ ...formErrors, name: "Name is required" });
      } else if (value.length > 50) {
        setFormErrors({ ...formErrors, name: "Maximum 50 characters allowed" });
      } else if (!nameRegex.test(value)) {
        setFormErrors({ ...formErrors, name: "Only alphabets allowed" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "email") {
      if (value === "") {
        setFormErrors({ ...formErrors, email: "Email is required" });
      } else if (!emailRegex.test(value)) {
        setFormErrors({ ...formErrors, email: "Invalid Email" });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "password") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          password: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({ ...formErrors, password: "Password is required" });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          password:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else if (name === "passwordConfirm") {
      if (value !== "" && value.length < 8) {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password must be at least 8 characters",
        });
      } else if (value === "") {
        setFormErrors({
          ...formErrors,
          passwordConfirm: "Password is required",
        });
      } else if (!passwordRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          passwordConfirm:
            "Please use atleast 1 uppercase, 1 lowercase, 1 special character, 1 numeric",
        });
      } else {
        setFormErrors({ ...formErrors, [name]: "" });
      }
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div className={style.back}>
          <Back
            title={
              location.pathname.includes("/subAdmin/editSubAdmin")
                ? SUB_ADMIN.BACK.TITLE_EDIT
                : SUB_ADMIN.BACK.TITLE_ADD
            }
            description={
              location.pathname.includes("/subAdmin/editSubAdmin")
                ? SUB_ADMIN.BACK.DESCRIPTION_EDIT
                : SUB_ADMIN.BACK.DESCRIPTION_ADD
            }
          />
        </div>
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              previewImage={formData?.profilePic}
              removeImage={removeImage}
              handleFile={handleFile}
              title={SUB_ADMIN.UPLOAD.TITLE}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => (
              <div
                key={index}
                className={style.input_field}
                style={{ width: inputField.width }}
              >
                <Input
                  type={inputField.type}
                  name={inputField.name}
                  label={inputField.label}
                  value={
                    inputField.name === "email"
                      ? formData[inputField.name]?.toLowerCase()
                      : formData[inputField.name]
                  }
                  placeholder={inputField.placeholder}
                  required={inputField.required}
                  onChange={(value) =>
                    handleInputChange(inputField.name, value)
                  }
                  options={inputField.options}
                  pattern={inputField.pattern}
                  maxLength={inputField.maxLength}
                  handleInputBlur={handleInputBlur}
                />
                <div className={style.error}>{formErrors[inputField.name]}</div>
              </div>
            ))}
            <div className={style.action_button}>
              <Button
                text={
                  location.pathname.includes("/subAdmin/editSubAdmin")
                    ? SUB_ADMIN.BUTTONS.TITLE_EDIT
                    : SUB_ADMIN.BUTTONS.TITLE_ADD
                }
                disabled={
                  isSubmitDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                }
                buttonType={
                  isSubmitDisabled ||
                  Object.values(formErrors).some((error) => Boolean(error))
                    ? `${buttonStyle.button_disabled}`
                    : `${buttonStyle.button_primary}`
                }
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default AddEditSubAdmin;
