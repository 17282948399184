import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { generateUUID } from "../../../../../hooks/uniqueIdGenerator";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { addSubscription } from "../../../../../redux/AurLaVieAdmin/manageSubscription/manageSubscription.actions";
import { addAstrologerProfile } from "../../../../../redux/AurLaVieAdmin/manageUsers/manageUsers.actions";
import { isLoading } from "../../../../../redux/progressLoader/progressLoader.actions";
import Button from "../../../../../shared/components/button/button";
import buttonStyle from "../../../../../shared/components/button/button.module.scss";
import Heading from "../../../../../shared/components/heading/heading";
import ImageUpload from "../../../../../shared/components/imageUpload/imageUpload";
import Input from "../../../../../shared/components/inputFields/input";
import {
  Born,
  Gender,
  isEditable,
  SexualPreference,
  TextAlign,
  Twins,
} from "../../../../../shared/globalConstants/constants";
import { USER_MANAGEMENT } from "../../userManagementConstants";
import style from "./addAstrologyProfile.module.scss";

const AddAstrologyProfile = (props) => {
  const { userId, userDetails, closed } = props;

  const inputFields = [
    {
      type: "text",
      name: "name",
      placeholder: "Name",
      label: "Name",
      required: true,
      width: "100%",
    },
    {
      type: "mui-date-picker",
      name: "dob",
      placeholder: "Date of Birth",
      label: "Date of Birth",
      width: "100%",
    },
    {
      type: "radio",
      name: "gender",
      placeholder: "Gender",
      label: "Gender",
      options: Gender,
      width: "50%",
    },
    {
      type: "radio",
      name: "twins",
      placeholder: "Are you twins?",
      label: "Are you twins?",
      options: Twins,
      width: "50%",
    },
    {
      type: "radio",
      name: "born",
      placeholder: "Born",
      label: "Born",
      options: Born,
      width: "50%",
    },
    {
      type: "radio",
      name: "sexualPreference",
      placeholder: "Sexual Preference",
      label: "Sexual Preference",
      options: SexualPreference,
      width: "50%",
    },
    {
      type: "radio",
      name: "isEditable",
      placeholder: "Admin Profile",
      label: "Admin Profile",
      options: isEditable,
      width: "50%",
    },
    {
      type: "mui-date-picker",
      name: "expiryDate",
      disableFuture: false,
      disablePast: true,
      placeholder: "Date of expiry",
      label: "Expiry date",
      width: "50%",
    },
  ];
  const [formData, setFormData] = useState({});
  const [toastList, setToastList] = useState([]);
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const [userImage, setUserImage] = useState("");

  useEffect(() => {
    const profileDetails = {
      userId,
      appId: localStorage.getItem("appId"),
      isEditable: false,
    };
    setUserImage("");
    setFormData(profileDetails);
  }, [userId, closed]);

  const handleInputChange = (fieldName, fieldValue) => {
    setFormData({ ...formData, [fieldName]: fieldValue });
  };

  const handleInputBlur = () => {};

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const uploadToS3 = async (file, name) => {
    dispatch(isLoading(true));
    const id = generateUUID();
    const fileKey = `user-profile-${id}.${file?.type.split("/")[1]}`;
    const params = {
      Body: file,
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: fileKey,
    };
    try {
      const upload = await myBucket.upload(params).promise();
      if (upload) {
        dispatch(isLoading(false));
        addAstrologyProfile(
          axiosInstance,
          formData,
          params,
          userId,
          upload.Location
        );
      }
    } catch (error) {
      console.log(error);
      setToastList([
        {
          id: 0,
          // title: "Error",
          title: "Error while uploading the image.",
          icon: "error",
        },
      ]);
      dispatch(isLoading(false));
    }
  };

  const handleFileAdd = (url) => {
    if (url) {
      setUserImage(url);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const params = {
      isAdmin: true,
    };
    if (userImage) {
      uploadToS3(userImage, userImage?.name);
    } else {
      addAstrologyProfile(axiosInstance, formData, params, userId, userImage);
    }
  };

  const addAstrologyProfile = (
    axiosInstance,
    details,
    params,
    userId,
    image
  ) => {
    if (image?.length > 0) {
      details["profilePic"] = image;
    } else {
      details["profilePic"] = null;
    }

    let { expiryDate, ...finalFormData } = details;
    dispatch(addAstrologerProfile(axiosInstance, finalFormData, params, userId))
      .then((response) => {
        addSubs(axiosInstance, {
          transactionId: "createdByAdmin",
          userId,
          appId: userDetails.appId._id,
          paymentStatus: "success",
          subscriptionStatus: "paid",
          subscriptionType: "Admin",
          subProfileId: response?.userProfile?._id,
          profileType: "AdminPremium",
          expiryDate,
        });
      })
      .catch((error) => {
        console.log("err", error);

        setToastList([
          {
            id: 0,
            // title: "Error fetching user details",
            title: error?.response?.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const addSubs = (axiosInstance, details) => {
    dispatch(addSubscription(axiosInstance, details))
      .then((response) => {
        console.log({ response });
        setToastList([
          {
            id: 0,
            title: "Astrology Profile Added Successfully",
            icon: "success",
          },
        ]);
        setTimeout(() => {
          closed("modal");
          setUserImage("");
          setFormData({});
        }, 1500);
      })
      .catch((error) => {
        console.log("err", error);
        setToastList([
          {
            id: 0,
            // title: "Error fetching user details",
            title: error?.response?.data.message,
            icon: "error",
          },
        ]);
      });
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Heading
          title={USER_MANAGEMENT.ADD_ASTROLOGY_PROFILE.HEADING.TITLE}
          description={
            USER_MANAGEMENT.ADD_ASTROLOGY_PROFILE.HEADING.DESCRIPTION
          }
          fontSize={"1.4rem"}
          textAlign={TextAlign.CENTER}
        />
        <div className={style.form_container}>
          <div className={style.upload}>
            <ImageUpload
              id="add_user_upload"
              title={USER_MANAGEMENT.ADD_USER.UPLOAD.TITLE}
              handleFile={handleFileAdd}
            />
          </div>
          <form onSubmit={handleSubmit}>
            {inputFields.map((inputField, index) => {
              return (
                <div
                  key={index}
                  className={style.input_field}
                  style={{ width: inputField.width }}
                >
                  <Input
                    disablePast={inputField.disablePast ? true : false}
                    type={inputField.type}
                    name={inputField.name}
                    label={inputField.label}
                    value={formData[inputField.name]}
                    placeholder={inputField.placeholder}
                    options={inputField.options}
                    required={inputField.required}
                    onChange={(value) =>
                      handleInputChange(inputField.name, value)
                    }
                    handleInputBlur={handleInputBlur}
                    disableFuture={inputField.disableFuture ? true : false}
                    views={["year", "day", "hours", "minutes"]}
                    openTo={"year"}
                    ampmRequired={false}
                  />
                </div>
              );
            })}
            <div className={style.action_button}>
              <Button
                text={USER_MANAGEMENT.ADD_ASTROLOGY_PROFILE.BUTTONS.TITLE}
                buttonType={`${buttonStyle.button_primary}`}
              />
            </div>
          </form>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </div>
  );
};

export default AddAstrologyProfile;
