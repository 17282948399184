import React, { useEffect } from "react";
import style from "./centeredModal.module.scss"; // Import the CSS file for styling

const CenterModal = ({ isOpen, onClose, minWidth, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <div className={`${style.center_modal} ${isOpen ? `${style.open}` : ""}`}>
      <div
        style={{ minWidth: minWidth }}
        className={style.center_modal_content}
      >
        <button className={style.close} onClick={onClose}>
          <img src="/icons/close.svg" alt="close" width={45} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default CenterModal;
