import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_PATH;
// export const baseURL = "https://aurlastage-be.clixlogix.org/v1/api/"
export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json'},
  // withCredentials: true
});

export const axiosPrivate = axios.create({
  baseURL: baseURL,
  headers: { 'Content-Type': 'application/json'},
  // withCredentials: true
});