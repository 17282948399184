import React from "react";
import "./switchButton.scss";

const SwitchButton = ({
  FirstIcon,
  SecondIcon,
  firstText,
  secondText,
  type,
  onCategoryClick,
  onTipsClick,
  isTips,
}) => {
  return (
    <div className="switchButton_container">
      <button
        type={type}
        style={{ borderRight: 0 }}
        className={`switchButton switchButton_left ${
          !isTips && "switchButton_active"
        }`}
        onClick={onCategoryClick}
      >
        {FirstIcon && <FirstIcon size={23} className="icon" />}
        <span className="switchButton--text">{firstText}</span>
      </button>
      <button
        type={type}
        style={{ borderLeft: 0 }}
        className={`switchButton switchButton_right ${
          isTips && "switchButton_active"
        }`}
        onClick={onTipsClick}
      >
        {SecondIcon && <SecondIcon size={23} className="icon" />}
        <span className="switchButton--text">{secondText}</span>
      </button>
    </div>
  );
};

export default SwitchButton;

// import React from "react"
// import './switchButton.scss'
// // {type === 'tips' && <TipsManagement />}
// // {type === 'category' && <CategoryManagement />}

// const SwitchButton = ({ FirstIcon, SecondIcon, firstText, secondText, type, onCategoryClick, onTipsClick, isTips }) => {
//   return (
//     <div className="switchButton_container">
//         <button type={type} style={{  borderRight: 0 }}
//         className={`switchButton switchButton_left ${isTips === 'category' && 'switchButton_active'}`} onClick={onCategoryClick} >
//             {FirstIcon && <FirstIcon size={23} className="icon"/>}
//             <span className="switchButton--text">{firstText}</span>
//         </button>
//         <button type={type} style={{  borderLeft: 0 }}
//         className={`switchButton switchButton_right ${isTips === 'tips' && 'switchButton_active'}`} onClick={onTipsClick} >
//             {SecondIcon && <SecondIcon size={23} className="icon"/>}
//             <span className="switchButton--text">{secondText}</span>
//         </button>
//     </div>
//   )
// }

// export default SwitchButton;
