import { useEffect, useRef, useState } from "react";
import Toaster from "../../../components/Global/Toaster/Toaster.component";
import {
  deleteBook,
  getBooks,
} from "../../../redux/AurLaVieAdmin/books/books.action";
import Button from "../../../shared/components/button/button";
import Heading from "../../../shared/components/heading/heading";
import Input from "../../../shared/components/inputFields/input";
import Table from "../../../shared/components/table/table";
import { USER_MANAGEMENT } from "../userManagement/userManagementConstants";
import style from "./booksManagement.module.scss";
import { BOOKS_MANAGEMENT } from "./booksManagementConstants";
import { useDispatch, useSelector } from "react-redux";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { getLanguages } from "../../../redux/utility/utility.action";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CenterModal from "../../../shared/components/modal/centeredModal/centeredModal";
import AddBooks from "./addBooks/addBooks";
import Delete from "../../../shared/components/delete/delete";
import { languageState } from "../../../redux/AurLaVieAdmin/languages/getLanguages.selector";

const BooksManagement = () => {
  const [toastList, setToastList] = useState([]);
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [books, setBooks] = useState([]);
  const navigate = useNavigate();
  const [showAddBook, setShowAddBook] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [languageId, setLanguageId] = useState(localStorage.getItem("languageId"));
  const [totalItem, setTotalItem] = useState(0);
  const [ids, setIds] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const languageData = useSelector(languageState);
  const currentDeleteRef = useRef()

  useEffect(() => {
    const params = {
      page,
      limit: pageSize,
      isAdmin: true,
      languageId,
      keyword: searchTerm,
    };
    getAllBooks(axiosInstance, params);
    console.log('22')
  }, [page]);

  useEffect(() => {
    setIsClosed(!showAddBook)
  }, [showAddBook])

  useEffect(() => {
    if (languageData.languageId) {
      setLanguageId(languageData.languageId)
      const params = {
        page: 1,
        limit: pageSize,
        isAdmin: true,
        languageId: languageData.languageId,
        keyword: searchTerm,
      };
      getAllBooks(axiosInstance, params);
    }
  }, [languageData]);

  useEffect(() => {
    let timerId;
    const fetchResults = async () => {
      try {
        setPage(1);
        const params = {
          page: 1,
          limit: pageSize,
          isAdmin: true,
          languageId: languageId,
          keyword: searchTerm,
        };

        getAllBooks(axiosInstance, params);
      } catch (error) {
        console.log(error);
      }
    };

    if (searchTerm || searchTerm === "") {
      timerId = setTimeout(() => {
        fetchResults();
      }, 500);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const handlePage = (page) => {
    setPage(page);
  };

  const getAllBooks = (axiosInstance, params) => {
    dispatch(getBooks(axiosInstance, params))
      .then((response) => {
        const updatedBooks = response?.listingData.map((book, index) => {
          const newIndex = ((page - 1) * pageSize) + index + 1;
          return {
            id: book?._id,
            "S. No":
              newIndex < 9
                ? `0${newIndex}`
                : `${newIndex}`,
            image: book?.bookImage ? book?.bookImage : '/icons/default.svg',
            bookName: book?.bookName,
            bookOrder: book?.bookOrder,
            createdOn: moment(book?.created_at).format("MM/DD/YYYY"),
            lastEditedOn: moment(book?.modified_at).format("MM/DD/YYYY"),
          };
        });
        setBooks(updatedBooks);
        setTotalItem(response?.meta?.total);
      })
      .catch((err) => {
        setToastList([
          {
            id: 0,
            // title: "Fething Books Failed",
            title: err?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const addBook = () => {
    setShowAddBook(true);
  };

  const closeModal = () => {
    setShowAddBook(false);
  };

  const onEdit = (row) => {
    navigate(`/aurlavie/booksManagement/editBook/${row.id}`);
  };

  const onDelete = (row) => {
    currentDeleteRef.current = [row.id];
    setShowDeleteModal(true);
  };

  const onSearch = (event) => {
    setSearchTerm(event);
  };

  const handleMultiple = (event) => {
    setIds(event);
  };

  const deletemany = () => {
    if (ids.length) {
      setShowDeleteModal(true);
    }
  };

  const handleDeleteNotification = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
    } else {
      const data = currentDeleteRef.current?.length
        ? { ids: currentDeleteRef.current }
        : { ids };

      dispatch(deleteBook(axiosInstance, data))
        .then((res) => {
          setShowDeleteModal(false);
          setIds(null);
          currentDeleteRef.current = null;
          setToastList([
            {
              id: 0,
              // title: "Books Deleted Successfully",
              title: res.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            const params = {
              page,
              limit: pageSize,
              isAdmin: true,
              languageId,
              keyword: searchTerm,
            };
            getAllBooks(axiosInstance, params);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete Notification Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const handleClose = (data) => {
    const languageId = localStorage.getItem("languageId");
    setShowAddBook(false);
    const params = {
      page: 1,
      limit: pageSize,
      isAdmin: true,
      languageId,
    };
    getAllBooks(axiosInstance, params);
  };

  return (
    <section className={style.container}>
      <div className={style.wrapper}>
        <CenterModal isOpen={showAddBook} onClose={closeModal}>
          <AddBooks
            handleClose={handleClose}
            isClosed={isClosed}
          />
        </CenterModal>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={BOOKS_MANAGEMENT.DELETE_BOOKS.TITLE}
            description={BOOKS_MANAGEMENT.DELETE_BOOKS.DESCRIPTION}
            onButtonClick={handleDeleteNotification}
          />
        </CenterModal>
        <section className={style.title_filters}>
          <Heading
            title={BOOKS_MANAGEMENT.HEADING.TITLE}
            description={BOOKS_MANAGEMENT.HEADING.DESCRIPTION}
            icon="/icons/books_blue.svg"
          />
          <div className={style.filters}>
            <Button
              icon={"/icons/add.svg"}
              text={"Add Book"}
              onClick={addBook}
              width={"180px"}
            />
            <Input
              type={"search"}
              placeholder={BOOKS_MANAGEMENT.FILTERS.SEARCH.PLACEHOLDER}
              value={searchTerm}
              onChange={onSearch}
            />
            <Button icon={"/icons/delete.svg"} onClick={deletemany} />
          </div>
        </section>
        <section className={style.listing}>
          <Table
            data={books}
            viewNotRequired={true}
            onEdit={onEdit}
            onDelete={onDelete}
            noDataText="No Books Found"
            totalItem={totalItem}
            handlePage={handlePage}
            pageNumber={page}
            handleMultiple={handleMultiple}
          />
        </section>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={2500}
      />
    </section>
  );
};

export default BooksManagement;
