import moment from "moment";
import style from "./viewSubAdmin.module.scss";
import Back from "../../../../../shared/components/backButton/backButton";
import Heading from "../../../../../shared/components/heading/heading";
import AppSwitchButton from "../../../../../shared/components/appSwitchButton/appSwitchButton";
import Button from "../../../../../shared/components/button/button";
import { USER_MANAGEMENT } from "../../../userManagement/userManagementConstants";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import {
  viewSubAdmin,
  deleteSubAdmin,
} from "../../../../../redux/AurLaVieAdmin/subAdmin/subAdmin.action";
import Toaster from "../../../../../components/Global/Toaster/Toaster.component";
import { SUB_ADMIN } from "../../subAdminUserRolesContants";
import CenterModal from "../../../../../shared/components/modal/centeredModal/centeredModal";
import Delete from "../../../../../shared/components/delete/delete";
import useExtractSubstring from "../../../../../hooks/useExtractSubstring";

const ViewSubAdmin = () => {
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();
  const [toastList, setToastList] = useState([]);
  const { id } = useParams();
  const [ids, setIds] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const params = {
      isAdmin: true,
    };
    viewSubAdminUser(axiosInstance, params, id);
  }, []);

  const viewSubAdminUser = (axiosInstance, params, userId) => {
    dispatch(viewSubAdmin(axiosInstance, params, userId))
      .then((response) => {
        setUserDetails(response);
      })
      .catch((error) => {
        console.log("er", error);
        setToastList([
          {
            id: 0,
            // title: "Error viewing user",
            title: error?.response?.data?.message,
            icon: "error",
          },
        ]);
      });
  };

  const onDelete = (data) => {
    setShowDeleteModal(true);
  };

  const handleDeleteRoles = (clicked) => {
    if (clicked === "Cancel") {
      setShowDeleteModal(false);
    } else {
      const data = { ids: id };

      dispatch(deleteSubAdmin(axiosInstance, data))
        .then((res) => {
          setShowDeleteModal(false);
          setToastList([
            {
              id: 0,
              // title: "SubAdmin Deleted Successfully",
              title: res.message,
              icon: "success",
            },
          ]);
          setTimeout(() => {
            navigate(`/subAdmin`);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          setToastList([
            {
              id: 0,
              // title: "Delete SubAdmin Error",
              title: err?.response?.data?.message,
              icon: "error",
            },
          ]);
        });
    }
  };

  const editUser = () => {
    navigate(`/subAdmin/editSubAdmin/${userDetails?._id}`);
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <CenterModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          minWidth={"300px"}
        >
          <Delete
            title={SUB_ADMIN.DELETE.TITLE}
            description={SUB_ADMIN.DELETE.DESCRIPTION}
            onButtonClick={handleDeleteRoles}
          />
        </CenterModal>
        <div className={style.back}>
          <Back
            title={SUB_ADMIN.VIEW_SUB_ADMIN.BACK.TITLE}
            description={SUB_ADMIN.VIEW_SUB_ADMIN.BACK.DESCRIPTION}
          />
        </div>
        <div className={style.heading}>
          <Heading
            title={useExtractSubstring(userDetails?._id)}
            description={USER_MANAGEMENT.VIEW_USER.HEADING.DESCRIPTION}
          />
          <div className={style.action_buttons}>
            <Button
              icon={"/icons/edit.svg"}
              text={"Edit User"}
              onClick={editUser}
            />
            <Button
              icon={"/icons/delete.svg"}
              text={"Delete User"}
              onClick={onDelete}
            />
          </div>
        </div>
        <div className={style.details}>
          <div className={style.profile_image}>
            <img
              src={
                userDetails?.profilePic
                  ? userDetails?.profilePic
                  : "/icons/user_blue.svg"
              }
              alt="profile"
            />
          </div>
          <div className={style.profile_details}>
            <div className={style.column}>
              <div className={style.row}>
                <div className={style.sub_detail}>
                  <label className={style.label}>Name</label>
                  <p className={style.value}>{userDetails?.name}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Email Id</label>
                  <p className={style.value}>{userDetails?.email}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>Contact Number</label>
                  <p className={style.value}>{userDetails?.contactNumber}</p>
                </div>
                <div className={style.sub_detail}>
                  <label className={style.label}>User Role</label>
                  <p className={style.value}>{userDetails?.roleId?.roleName}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toastList={toastList}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={3500}
      />
    </div>
  );
};

export default ViewSubAdmin;
