/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import style from "./sidebar.module.scss";
import { SETTINGS_MANAGEMENT } from "../../../pages/admin/settings/settings.constant";
import { NOTIFICATION_MANAGEMENT } from "../../../pages/admin/notification/notification.constant";
import { useDispatch, useSelector } from "react-redux";
import { getAccessBasedSideMenu } from "../../../redux/utility/utility.action";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { setType } from "../../../redux/FengShui/toggle";

const SidebarComponent = () => {
  const location = useLocation();
  const [subscribed, setSubscribed] = useState(false);
  const [userType, setUserType] = useState("");
  const [sideBarList, setSideBarList] = useState([]);
  const [newActiveLink, setNewActiveLink] = useState(null);
  const [newInnerActiveLink, setNewInnerActiveLink] = useState(null);
  const dispatch = useDispatch();
  const [toastList, setToastList] = useState();
  const axiosInstance = useAxiosInstance();
  const { type } = useParams();

  const [sidebarItems, setSidebarItems] = useState([]);

  const sideBarRef = useRef();
  useEffect(() => {
    let newSidebarValues = [];
    switch (userType?.toLowerCase()) {
      case "aptillio-admin":
        newSidebarValues = [];
        break;
      default:
        break;
    }
    sidebarItems.unshift(...newSidebarValues);

    setSideBarList(sidebarItems);
    const params = {
      isAdmin: true,
    };
    const userId = localStorage.getItem("userId");

    getMenuItems(axiosInstance, params, userId);
  }, [userType]);

  const collapse = (index) => {
    setSideBarList((prevState) =>
      prevState.map((item, i) =>
        i === index ? { ...item, collapsed: !item.collapsed } : item
      )
    );
  };

  const getMenuItems = (axiosInstance, params, userId) => {
    dispatch(getAccessBasedSideMenu(axiosInstance, params, userId))
      .then((response) => {
        sideBarRef.current = response;

        const updatedMenu = response.map((item) => {
          const { _id, permissionDetails } = item;
          if (_id !== null) {
            const { order, permissionName, permissionIcon, permissionLink } =
              _id;
            const subItems = permissionDetails.map((detail) => {
              const { permissionName, permissionIcon, permissionLink, order } =
                detail;
              return {
                name: permissionName,
                route: permissionLink,
                icon: permissionIcon,
                order: order,
              };
            });
            const subItemsByOrder = sortObjectByOrder(subItems);
            return {
              appName: permissionName,
              icon: permissionIcon,
              order: order,
              subItems: subItemsByOrder,
            };
          } else {
            return permissionDetails.map((detail) => {
              const { order, permissionName, permissionIcon, permissionLink } =
                detail;
              return {
                name: permissionName,
                route: permissionLink,
                icon: permissionIcon,
                order: order,
              };
            });
          }
        });

        const sortedByOrder = sortObjectByOrder(updatedMenu.flat());

        sortedByOrder.push({
          icon: "profile",
          name: "Profile Management",
          route: "/profileManagement",
        });
        setSideBarList(sortedByOrder);
      })
      .catch((error) => {
        setToastList([
          {
            id: 0,
            // title: "Error fetching content",
            title: error.response?.data.message,
            icon: "error",
          },
        ]);
      });
  };

  const sortObjectByOrder = (objectArray) => {
    const sortedArray = objectArray.sort((a, b) => a.order - b.order);
    return sortedArray;
  };

  const handleAppId = (data) => {
    let currentRef;
    console.log(sideBarRef);
    sideBarRef.current.map((element) => {
      element.permissionDetails.map((innerElement) => {
        if (innerElement.permissionLink === data.route) {
          currentRef = innerElement.appId;
        }
      });
    });

    if (currentRef) {
      localStorage.setItem("appId", currentRef);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {sideBarList?.map((sidebar, i) => {
          if (
            location?.pathname === "/subscriptionPlans" &&
            userType === "org-admin" &&
            !subscribed
          ) {
            return (
              <NavLink
                onClick={(e) => e.preventDefault()}
                end={sidebar.route === "/"}
                key={i}
                to={sidebar.route}
                exact={"/"}
                activeclassname={style.active}
                className={({ isActive }) =>
                  isActive ? `${style.active}` : `${style.inactive}`
                }
              >
                {sidebar.icon}
                <p className={style.label}>{sidebar.name}</p>
              </NavLink>
            );
          }
          return (
            <React.Fragment key={i}>
              {!sidebar.hasOwnProperty("appName") ? (
                <NavLink
                  onClick={() => handleAppId(sidebar)}
                  end={sidebar.route === "/"}
                  key={i}
                  to={sidebar.route}
                  exact={"/"}
                  isActive={(match, location, i) => {
                    match && setNewActiveLink(i);
                    setNewActiveLink(match);
                    return match;
                  }}
                  className={({ isActive }) =>
                    isActive
                      ? `${style.active} ${style.link}`
                      : `${style.inactive} ${style.link}`
                  }
                >
                  {location.pathname.includes(sidebar.route) ? (
                    <img
                      className={style.icon}
                      src={"/sidebar/" + `${sidebar.icon}` + "_white.svg"}
                      style={{ height: "20px", width: "25px" }}
                    />
                  ) : (
                    <img
                      className={style.icon}
                      src={"/sidebar/" + `${sidebar.icon}` + "_grey.svg"}
                      style={{ height: "20px", width: "25px" }}
                    />
                  )}
                  <p className={style.label}>{sidebar.name}</p>
                </NavLink>
              ) : (
                <div className={style.appLevel} key={i}>
                  <div
                    className={
                      (location.pathname.includes("fengshui") &&
                        sidebar.appName === "Feng Shui") ||
                      (location.pathname.includes("aurlavie") &&
                        sidebar.appName === "Auracle Life")
                        ? `${style.activeApp}`
                        : `${style.app}`
                    }
                  >
                    {(location.pathname.includes("fengshui") &&
                      sidebar.appName === "Feng Shui") ||
                    (location.pathname.includes("aurlavie") &&
                      sidebar.appName === "Auracle Life") ? (
                      <img
                        className={style.icon}
                        src={"/sidebar/" + `${sidebar.icon}` + "_blue.svg"}
                        style={{ height: "20px", width: "25px" }}
                      />
                    ) : (
                      <img
                        className={style.icon}
                        src={"/sidebar/" + `${sidebar.icon}` + "_grey.svg"}
                        style={{ height: "20px", width: "25px" }}
                      />
                    )}
                    <p className={style.label}>{sidebar.appName}</p>
                    <img
                      src="/icons/arrow_bottom_blue.svg"
                      alt="down-arrow"
                      className={
                        sidebar.collapsed ? style.rotateArrow : style.arrow
                      }
                      onClick={() => collapse(i)}
                    />
                  </div>
                  {!sidebar.collapsed && (
                    <div className={style.subItemContainer}>
                      {sidebar.subItems.map((subItem, index) => {
                        return (
                          <NavLink
                            onClick={() => handleAppId(subItem)}
                            end={subItem.route === "/"}
                            key={index}
                            to={
                              subItem.name === "Tips Management"
                                ? `${subItem.route}/${type || "tips"}`
                                : subItem.route
                            }
                            exact={"/"}
                            isActive={(match, location, i) => {
                              match && setNewInnerActiveLink(i);
                              setNewInnerActiveLink(match);
                              return match;
                            }}
                            activeclassname={style.active}
                            className={({ isActive }) =>
                              isActive
                                ? `${style.active} ${style.innerLink}`
                                : `${style.inactive} ${style.innerLink}`
                            }
                          >
                            <div className={style.subItem}>
                              {location.pathname.includes(subItem.route) ? (
                                <img
                                  className={style.icon}
                                  src={
                                    "/sidebar/" +
                                    `${subItem.icon}` +
                                    "_white.svg"
                                  }
                                  style={{ height: "20px", width: "25px" }}
                                />
                              ) : (
                                <img
                                  className={style.icon}
                                  src={
                                    "/sidebar/" +
                                    `${subItem.icon}` +
                                    "_grey.svg"
                                  }
                                  style={{ height: "20px", width: "25px" }}
                                />
                              )}
                              <p className={style.label}>{subItem.name}</p>
                            </div>
                          </NavLink>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SidebarComponent;
