import React, { useEffect, useState } from "react";
import style from "./input.module.scss";
import { FaChevronDown, FaSearch } from "react-icons/fa";
import useClickOutside from "../../../hooks/useClickOutside";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
const Input = ({
  type,
  name,
  label,
  placeholder,
  required,
  value,
  width,
  onChange,
  onKeyDown,
  handleInputBlur,
  options,
  isDisabled,
  disablePast,
  disableFuture,
  rows,
  views,
  openTo,
  mainWidth,
  optionsSelected,
  handleOptions,
  maxLength,
  ampmRequired,
  removeHover,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { ref, isVisible, setIsVisible } = useClickOutside(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isEye, setIsEye] = useState(false);
  const handleEye = () => setIsEye(!isEye);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      const selectedOption = selectedOptions.filter((item) => item !== option);
      if (optionsSelected) {
        optionsSelected(selectedOption);
      }
      setSelectedOptions(selectedOption);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      if (optionsSelected) {
        optionsSelected([...selectedOptions, option]);
      }
    }
  };

  useEffect(() => {
    if (handleOptions) {
      setSelectedOptions(handleOptions);
    }
  }, [handleOptions]);
  console.log();
  const showEnteredPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsVisible(true);
  };

  const selectOption = (option) => {
    onChange(option);
    setIsOpen(false);
    setIsVisible(false);
  };

  const renderInput = () => {
    switch (type) {
      case "text":
        return (
          <div className={`${style.input_field}`}>
            <input
              maxLength={maxLength}
              disabled={isDisabled}
              className={style.custom_input}
              type="text"
              name={name}
              id={label}
              placeholder={placeholder}
              required={required}
              value={value || ""}
              onChange={(e) => onChange(e.target.value)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              onKeyUp={handleInputBlur && handleInputBlur}
            />
          </div>
        );
      case "Privacytext":
        return (
          <div className={`${style.input_field}`}>
            <input
              maxLength={maxLength}
              disabled={isDisabled}
              type={isEye ? "text" : "password"}
              name={name}
              id={label}
              placeholder={placeholder}
              required={required}
              value={value || ""}
              onChange={(e) => onChange(e.target.value)}
              onKeyDown={(e) => onKeyDown && onKeyDown(e)}
              onKeyUp={handleInputBlur && handleInputBlur}
            />
            {isEye ? (
              <VscEye onClick={handleEye} className={style.password_eye} />
            ) : (
              <VscEyeClosed
                onClick={handleEye}
                className={style.password_eye}
              />
            )}
          </div>
        );
      case "textarea":
        return (
          <div className={`${style.input_field}`}>
            <textarea
              name={name}
              id={label}
              placeholder={placeholder}
              required={required}
              value={value || ""}
              rows={rows || 10}
              onChange={(e) => onChange(e.target.value)}
              onKeyUp={handleInputBlur && handleInputBlur}
            />
          </div>
        );
      case "number":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="number"
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              onKeyUp={handleInputBlur && handleInputBlur}
              value={value || ""}
              onChange={(e) => onChange(parseFloat(e.target.value))}
            />
          </div>
        );
      case "color":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="color"
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              onKeyUp={handleInputBlur && handleInputBlur}
              value={value || ""}
              onChange={(e) => onChange(parseFloat(e.target.value))}
            />
          </div>
        );
      case "email":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="email"
              className={style.custom_input}
              id={label}
              name={name}
              disabled={isDisabled}
              required={required}
              placeholder={placeholder}
              value={value || ""}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case "password":
        return (
          <div className={`${style.input_field}`}>
            <input
              type={showPassword ? "text" : "password"}
              id={label}
              name={name}
              required={required}
              placeholder={placeholder}
              value={value || ""}
              onChange={(e) => onChange(e.target.value)}
              onKeyUp={handleInputBlur && handleInputBlur}
            />
            {type === "password" ? (
              showPassword ? (
                <MdOutlineVisibility onClick={showEnteredPassword} />
              ) : (
                <MdOutlineVisibilityOff onClick={showEnteredPassword} />
              )
            ) : null}
          </div>
        );
      case "search":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="search"
              id={label}
              name={name}
              placeholder={placeholder}
              value={value || ""}
              style={{ width: width }}
              onChange={(e) => onChange(e.target.value)}
            />
            <FaSearch />
          </div>
        );
      case "dropdown":
        return (
          <div
            className={`${style.input_field}`}
            onClick={toggleDropdown}
            style={{ width: width }}
          >
            <div className={`${style.dropdown_header}`}>
              {value?.label !== undefined ? value?.label : placeholder}{" "}
              <FaChevronDown />
            </div>
            {isOpen && isVisible && (
              <ul ref={ref} className={`${style.dropdown_list}`}>
                {options.map((option) => (
                  <li key={option.value} onClick={() => selectOption(option)}>
                    {option.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      case "multi-select-dropdown":
        return (
          <div className={style.input_field}>
            <div className={style.dropdown_header} onClick={toggleDropdown}>
              {/* {selectedOptions.length > 0 ? selectedOptions.map(option => {
                return <span className={style.option}>
                  <span className={style.lable}>{option.label}</span>
                  <span className={style.remove} onClick={() => handleOptionClick(option)}>&times;</span>
                </span>
              }) : placeholder}
              <FaChevronDown />
              }) : placeholder} */}
              {placeholder}
              <FaChevronDown />
            </div>
            {isOpen && isVisible && (
              <ul ref={ref} className={style.dropdown_list}>
                {options?.map((option) => (
                  <li className={style.multi_list} key={option.value}>
                    <label className={style.checkbox_container}>
                      <input
                        type="checkbox"
                        id={option.value}
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionClick(option)}
                      />
                      <span className={style.checkmark}></span>
                      <span className={style.title}>{option.label}</span>
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      case "radio":
        return (
          <div className={style.radio_field}>
            {options.map((option) => (
              <label
                className={`${style.radio_container} ${style.ra_label}`}
                key={option.value}
              >
                <input
                  type="radio"
                  className={removeHover ? style.radio_remove : ""}
                  name={name}
                  id={option.label}
                  value={option.value || ""}
                  checked={value === option.value}
                  disabled={option?.disabled || false}
                  onChange={() => onChange(option.value)}
                />
                <span className={style.checkmark}></span>
                <span className={style.radio_label}>{option.label}</span>
              </label>
            ))}
          </div>
        );
      case "checkbox":
        return (
          <div>
            {options.map((option) => (
              <label key={option.value}>
                <input
                  type="checkbox"
                  id={option.label}
                  name={name}
                  value={option.value || ""}
                  checked={value.includes(option.value)}
                  onChange={() => {
                    const updatedValue = [...value];
                    if (value.includes(option.value)) {
                      updatedValue.splice(value.indexOf(option.value), 1);
                    } else {
                      updatedValue.push(option.value);
                    }
                    onChange(updatedValue);
                  }}
                />
                {option.label}
              </label>
            ))}
          </div>
        );
      case "date":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="date"
              name={name}
              id={label}
              required={required}
              value={value || ""}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case "mui-date-picker":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={["MobileDateTimePicker", "MobileDateTimePicker"]}
            >
              {console.log({
                value,
                // utcValue: dayjs.utc(value),
                // isUtcVal: value?.isUTC(),
              })}
              <MobileDateTimePicker
                slotProps={{
                  textField: {
                    size: "small",
                    error: false,
                  },
                }}
                sx={{
                  ".MuiInputBase-input": {
                    color: "#8D98AF",
                    padding: "13px 14px",
                  },
                }}
                disabled={isDisabled}
                disablePast={disablePast}
                disableFuture={disableFuture}
                closeOnSelect={false}
                id={label}
                ampm={ampmRequired}
                views={views}
                value={dayjs.utc(value).local()}
                format="YYYY/MM/DD hh:mm a"
                onChange={(data) => onChange(data.$d)}
                openTo={openTo}
              />
            </DemoContainer>
          </LocalizationProvider>
        );
      case "time":
        return (
          <div className={`${style.input_field}`}>
            <input
              type="time"
              name={name}
              id={label}
              value={value || ""}
              required={required}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      case "datetime":
        return (
          <div className={`${style.input_field}`}>
            <input
              disabled={isDisabled}
              type="datetime-local"
              id={label}
              name={name}
              value={value || ""}
              required={required}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`${style.input_fields_container}`}
      style={{ width: mainWidth }}
    >
      {label !== undefined ? (
        <label htmlFor={label} className={style.label}>
          {label} {required ? <span className={style.required}>*</span> : null}
        </label>
      ) : null}
      {renderInput()}
    </div>
  );
};

export default Input;
