import { useState } from "react";
import SwitchButton from "../../../shared/components/switchButton/switchButton";
import style from './subAdminUserRoles.module.scss';
import SubAdmin from "./subAdmin/subAdmin";
import UserRoles from "./userRoles/userRoles";

const SubAdminUserRoles = () => {

    const [isTips, setIsTips] = useState(false);
    const onCategoryClick = () => setIsTips(false);
    const onTipsClick = () => setIsTips(true);

    return <div className={style.container} >
        <div className={style.wrapper}>
            <SwitchButton
                firstText="Sub Admin Users"
                secondText="User Roles"
                onCategoryClick={onCategoryClick}
                onTipsClick={onTipsClick}
                isTips={isTips}
            />
            {!isTips ? <SubAdmin /> : <UserRoles />}
        </div>
    </div>
}

export default SubAdminUserRoles;