import React, { useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import LandingScreen from "../admin/LandingPage/landing";
import "./home.styles.scss";

const Homepage = () => {
  const location = useLocation();
  const navigateTo = useNavigate();
  const checkAuth = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("accessToken");
    const remember = localStorage.getItem("keepMeLoggedIn");

    if (location.state) {
      return true;
    } else {
      if (remember == "false") {
        navigateTo("/login");
      } else {
        if (!userId || !token) {
          navigateTo("/login");
        } else {
          navigateTo('/home')
        }
      }
    }
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <div className="homepage_container">
      <div className="homepage_wrapper">
        <LandingScreen />
      </div>
    </div>
  );
};

export default Homepage;
